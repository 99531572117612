import http from '@/utils/http'

// 获取广告列表
export function getBanList(params){
    let url = '/portal/banner/get-list'
    return http.get(url, params)
}

// 新增联系我们
export function addContact(params){
    let url = '/portal/contact-user/add'
    return http.post(url, params)
}

// 新闻资讯详情
export function getNewsInfo(params){
    let url = '/portal/news/get-info'
    return http.get(url, params)
}

// 获取门户资讯列表
export function getDataNewslist(params){
    let url = '/portal/news/get-data-list'
    return http.get(url, params)
}

// 获取门户最新新闻资讯列表
export function getNewslist(params){
    let url = '/portal/news/get-list'
    return http.get(url, params)
}

// 获取服务客户列表
export function getServeList(params){
    let url = '/portal/partner/get-list'
    return http.get(url, params)
}

// 获取类型字典列表
export function getTypeList(params){
    let url = '/portal/type-config/get-list'
    return http.get(url, params)
}

// 获取课程案例列表
export function getCaseList(params){
    let url = '/portal/course-case/get-list'
    return http.get(url, params)
}

// 批量更新前端路由
export function setPortalRoutes(params){
    let url = '/portal/type-config/add-portal-route'
    return http.post(url, params)
}

// 获取官网状态（是否置灰） 
export function companyStatus(params){
    let url = '/portal/type-config/company-status'
    return http.get(url, params)
}