<template>
    <div class="main-head">
        <div class="head">
            <div class="logo-cont">
                <img :src="logo" alt="">
            </div>
            <div class="menu-cont horizontal-nemu">
                <div class="svgIcon-cont" @click="showMenu()">
                    <svg-icon icon-class="menu" class="svgIcon" />
                </div>
                <!--大屏横向导航-->
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                    active-text-color="#DD2D25" @select="(data) => { return handleSelect(data, 'default')}">
                    <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.path">
                        <span v-if="!item.menuItem">{{item.label}}</span>
                        <el-dropdown v-if="item.menuItem" @command="handleCommand" trigger="hover">
                            <span class="el-dropdown-link">{{item.label}}
                                <i class="el-icon-arrow-down"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dropdown-cont">
                                <el-dropdown-item v-for="(el, inx) in item.menuItem" :key="inx" :command="el.path"
                                    class="dropdown-sortTitle">
                                    {{el.label}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-menu-item>
                </el-menu>
            </div>
        </div>
        <!--小屏纵向导航-->
        <div class="show-menu">
            <el-menu :default-active="activeIndex" mode="vertical"
                @select="(data) => { return handleSelect(data, 'mini')}" class="menu-ul">
                <div v-for="(item, index) in menuList" :key="index" class="menu-ul-item">
                    <el-submenu v-if="item.menuItem" :index="item.path">
                        <template slot="title">
                            <span>{{item.label}}</span>
                        </template>
                        <div v-for="(el, inx) in item.menuItem" :key="inx">
                            <el-menu-item :index="el.path">{{el.label}}</el-menu-item>
                        </div>
                    </el-submenu>
                    <el-menu-item v-else :index="item.path">{{item.label}}</el-menu-item>
                </div>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: "Head",
    components: {},
    data() {
        return {
            logo: require("@/assets/logo.png"),
            menuList: [
                {
                    label: "首页",
                    path: "/home/homePage",
                },
                {
                    label: "业务",
                    path: "/home/digital",
                    menuItem: [
                        {
                            label: "数字化资源建设",
                            path: "/home/digital",
                        },
                        {
                            label: "虚拟仿真项目",
                            path: "/home/virtualSimulation",
                        },
                        {
                            label: "智慧空间建设",
                            path: "/home/wisdomSpace",
                        },
                        {
                            label: "教师发展培训",
                            path: "/home/teacherTraining",
                        },
                        {
                            label: "智慧教学平台",
                            path: "/home/wisdomOnline",
                        },
                    ]
                },
                {
                    label: "客户",
                    path: "/home/customer",
                },
                {
                    label: "新闻",
                    path: "/home/news",
                },
                {
                    label: "关于",
                    path: "/home/about",
                },
                {
                    label: "联系我们",
                    path: "/home/contactUs",
                },
            ],
            activeIndex: "/home/homePage",
            isShow: false,
        };
    },
    watch: {
        $route() {
            this.setCurrentRoute()
        }
    },
    computed: {
    },
    created() {
        this.setCurrentRoute()
    },
    mounted() { },
    methods: {
        setCurrentRoute() {
            console.log(this.$route.path, '---------this.$route.path');
            this.activeIndex = this.$route.path; //监听到当前路由状态并激活当前菜单
            let pathArr = ['/home/virtualSimulation', '/home/wisdomSpace', '/home/teacherTraining', '/home/wisdomOnline']
            if (pathArr.includes(this.$route.path)) {
                this.activeIndex = '/home/digital'
            }

            let news_pathArr = ['/home/newsDetail', '/home/newsList']
            if (news_pathArr.includes(this.$route.path)) {
                this.activeIndex = '/home/news'
            }
        },
        handleSelect(key, str) {
            console.log(key, '---------------1');
            this.$router.push({
                path: key
            })
            if (str === "mini") {
                let dis = document.querySelector('.show-menu')
                // let disP = document.querySelector('.mouse-arrow')
                dis.setAttribute("class", "show-menu hide_menu");
                // disP.style['z-index'] = 300
            }
        },
        handleCommand(command) {
            console.log(command, '------------command');
            this.$router.push({
                path: command
            })
        },
        showMenu() {
            let dis = document.querySelector('.show-menu')
            // let disP = document.querySelector('.mouse-arrow')
            if (this.isShow) {
                this.isShow = false
                dis.setAttribute("class", "show-menu hide_menu");
                // disP.style['z-index'] = 300
            } else {
                this.isShow = true
                dis.setAttribute("class", "show-menu show_menu");
                // disP.style['z-index'] = 0
            }
        }
    },
};
</script>
<style scoped lang="scss">
.head {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-cont {
        width: 350px;
        // height: 50px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .svgIcon {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }
}

::v-deep .el-menu li{
    font-size: 16px !important;
}

::v-deep .el-dropdown{
    font-size: 16px !important;
}

.show-menu {
    .menu-ul {
        display: none;
    }
}

.show_menu {
    .menu-ul {
        display: block;
        animation: fadeIn 1s;
    }
}

.hide_menu {
    .menu-ul {
        display: none;
        animation: hideIn 1s;
    }
}

@keyframes fadeIn {
    0% {
        display: none;
    }

    100% {
        display: block;
    }
}

@keyframes hideIn {
    0% {
        display: block;
    }

    100% {
        display: none;
    }
}

@media screen and (max-width:768px) {
    .head {
        padding: 0px 10px;
        background-color: #fff;
    }

    .el-menu-demo {
        display: none;
    }
}

@media screen and (min-width: 900px) {
    .head {
        padding: 0px 30px;
    }

    .svgIcon-cont {
        display: none;
    }
}
</style>
  