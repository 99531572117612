<template>
    <div class="home">
        <el-container class="container">
            <div class="el-header">

                <Head></Head>
            </div>
            <div class="topHeight"></div>
            <el-main>
                <Main class="main-cont"></Main>
            </el-main>
        </el-container>
    </div>
</template>
  
<script>
import Head from "@/views/Head";
import Main from "@/views/Main";
export default {
    name: "Home",
    components: {
        Head,
        Main,
    },
    data() {
        return {
        };
    },
    computed: {
    },
    mounted() { },
    created() {
    },
    methods: {
    },
    destroyed() { },
};
</script>
<style scoped lang="scss">
.home {
    width: 100%;
    height: 100%;
    padding: 0px;
    // background-color: #fff;
}

.container {
    position: relative;
}

.el-header {
    padding: 0px;
    position: absolute;
    top: 0px;
    z-index: 100;
    width: 100%;
    // background-color: rgba(255, 255, 255, 0.5);
    background-color: rgba($color: #fff, $alpha: 0.5);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
}

.topHeight {
    height: 70px;
    width: 100%;
    background-color: #fff;
}
</style>
  