//http.js
//导入封装好的axios实例
import request from './request'

const http = {
    //Get请求 
    get(url, params) {
        const config={
            method :'get',
            url:url
        }
        if(params) config.params = params
        return request(config)
    },
    
	//POST请求
    post(url,params){
        const config = {
          method: 'post',
          url: url,
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          }
        }
        if(params) config.data = params
        return request(config)
    },
}
//导出
export default http