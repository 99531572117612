import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";

//解决产生了冗余导航，vue-router报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  //首页
  {
    path: "/home",
    name: "Home",
    redirect: '/home/homePage',
    component: Home,
    children: [
      {
        path: "homePage",
        name: "HomePage",
        component: () => import('@/views/homePage'),
      },
      //业务相关
      {
        path: "digital",
        name: "Digital",
        component: () => import('@/views/business/digital.vue'), //数字化资源建设
      },
      {
        path: "teacherTraining",
        name: "TeacherTraining",
        component: () => import('@/views/business/teacherTraining.vue'), //教师发展培训
      },
      {
        path: "virtualSimulation",
        name: "VirtualSimulation",
        component: () => import('@/views/business/virtualSimulation.vue'), //虚拟仿真项目
      },
      {
        path: "wisdomOnline",
        name: "WisdomOnline",
        component: () => import('@/views/business/wisdomOnline.vue'), //智慧在线学习云平台
      },
      {
        path: "wisdomSpace",
        name: "WisdomSpace",
        component: () => import('@/views/business/wisdomSpace.vue'), //智慧空间建设
      },
      //客户
      {
        path: "customer",
        name: "Customer",
        component: () => import('@/views/customer'),
      },
      //新闻
      {
        path: "news",
        name: "News",
        component: () => import('@/views/news'),
      },
      //新闻列表
      {
        path: "newsList",
        name: "NewsList",
        component: () => import('@/views/news/components/newsList.vue'),
      },
      //新闻详情
      {
        path: "newsDetail",
        name: "NewsDetail",
        component: () => import('@/views/news/components/newsDetail.vue'),
      },
      //关于
      {
        path: "about",
        name: "About",
        component: () => import('@/views/about'),
      },
      //新闻
      {
        path: "contactUs",
        name: "ContactUs",
        component: () => import('@/views/contactUs'),
      },
    ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
